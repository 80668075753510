import React, { useState } from "react";
import {
  Edit,
  SimpleForm,
  BooleanInput,
  SelectInput,
  TextInput,
  FormDataConsumer,
  required,
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarEdit } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

const CustomTitle = (props) => `Baner ${props.record.name}`;

const requiredField = required();

export default (props) => {
  const [position, setPosition] = useState();

  return (
    <Edit {...props} title={<CustomTitle />}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            setPosition(formData.position);
          }}
        </FormDataConsumer>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nagłówek"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                    <td>
                      <SelectInput
                        source="type"
                        variant="outlined"
                        label="Typ"
                        choices={[
                          { id: 0, name: "Baner na stronie głównej" },
                          { id: 1, name: "Baner w Centrum Zdrowego Kręgosłupa" },
                        ]}
                        fullWidth={true}
                        validate={requiredField}
                      />
                    </td>
                  </tr>
                <tr>
                  <td>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <TextInput
                      source="url"
                      label="URL"
                      variant="outlined"
                      fullWidth
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddImagePopup
                        source="image"
                        reference="media_objects"
                        label="Grafika"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                      source="public"
                      label="Publiczne"
                      variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};
