import React, { useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  required,
  BooleanInput,
  NumberInput,
  TabbedForm,
  FormTab
} from "react-admin";
import { Field } from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import authProvider from "../../config/authProvider";

import { PostBottomToolbarAdd } from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";
import AddFilePopup from "../CustomElements/AddFilePopup";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={`Dodaj poradnię`}>
      <TabbedForm toolbar={<PostBottomToolbarAdd />}>
        <FormTab label={'Ogólne'}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddImagePopup source="icon" reference="media_objects" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Treść krótka
                    </label>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Opis pełny
                    </label>
                    <Field name="description">
                      {(props) => <TinyMCEInput source="description" />}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <NumberInput
                        source="portalId"
                        label="ID potrału"
                        variant="outlined"
                        fullWidth
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="hospitalSection"
                      reference="hospital_sections"
                      label="Oddziały szpitalu"
                      perPage={1000}
                      fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="diagnosis"
                      reference="diagnoses"
                      label="Diagnostyki"
                      perPage={1000}
                      fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddFilePopup
                      source="files"
                      reference="files"
                      label="Do pobrania"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="staff"
                      reference="staff"
                      label="Lekarze"
                      perPage={1000}
                      fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="prices"
                        reference="prices"
                        label="Cennik"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="blogs"
                        reference="blogs"
                        label="Artykuły blogowe"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        </FormTab>
          <FormTab label={'Centrum Zdrowego Kręgosłupa'}>
            <div className="d-flex w-100 justify-content-around">
              <div class="col-xs-6">
                <div class="table">
                  <table class="table table-striped">
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Treść krótka Centrum Zdrowego Kręgosłupa
                      </label>
                      <Field name="shortCentrum">
                        {(props) => (
                          <TinyMCEInput
                            source="shortCentrum"
                            height={200}
                            menubar={false}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Opis długi Centrum Zdrowego Kręgosłupa
                      </label>
                      <Field name="descriptionCentrum">
                        {(props) => <TinyMCEInput source="descriptionCentrum" />}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                  <td>
                    <AddImagePopup source="iconCentrum" reference="media_objects" />
                  </td>
                </tr>
                <tr>
                   <td>
                      <NumberInput
                        source="positionCentrum"
                        label="Pozycja w Centrum Zdrowego Kręgosłupa"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="showCentrum"
                        label="Pokaż w Centrum Zdrowego Kręgosłupa"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  </table>
                </div>
              </div>
            </div>
          </FormTab>
        </TabbedForm>
    </Create>
  );
};
