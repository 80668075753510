import * as React from "react";
import {Filter, List, TextField, TextInput,BooleanField, DateField} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import {CustomActionsButtons} from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

export default (props) => {
  return (
    <List
      {...props}
      title={`Lista blogów`}
      perPage={25}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="blogów" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>
        <TextField source="name" label="Nazwa" />
        {/*<ReferenceArrayField*/}
        {/*  source="clinic"*/}
        {/*  reference="clinics"*/}
        {/*  label="Poradni"*/}
        {/*>*/}
        {/*  <SingleFieldList>*/}
        {/*    <ChipField source="name" />*/}
        {/*  </SingleFieldList>*/}
        {/*</ReferenceArrayField>*/}
        {/*<ReferenceArrayField*/}
        {/*  source="hospitalSection"*/}
        {/*  reference="hospital_sections"*/}
        {/*  label="Oddziały szpitala"*/}
        {/*>*/}
        {/*  <SingleFieldList>*/}
        {/*    <ChipField source="name" />*/}
        {/*  </SingleFieldList>*/}
        {/*</ReferenceArrayField>*/}
        {/*<ReferenceArrayField source="tags" reference="blog_tags" label="Tagi">*/}
        {/*  <SingleFieldList>*/}
        {/*    <ChipField source="name" />*/}
        {/*  </SingleFieldList>*/}
        {/*</ReferenceArrayField>*/}
        <DateField source="createdAt" label="Utworzono" />
        <DateField source="updatedAt" label="Modyfikowano" />
          <BooleanField
              source="public"
              label="Opublikowany"
          />
        <CustomActionsButtons label="Akcje" notUndoable={true}/>
      </CustomDataGrid>
    </List>
  );
};
