import React from "react";
import {
  AutocompleteArrayInput,
  Create,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  BooleanInput,
  TabbedForm, 
  FormTab
} from "react-admin";
import {Field} from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import {PostBottomToolbarAdd} from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

const requiredField = required();

export default (props) => {
  return (
    <>
      <Create {...props} title={`Dodaj lekarza`}>
        <TabbedForm toolbar={<PostBottomToolbarAdd />}>
          <FormTab label={'Ogólne'}>
          <div className="d-flex w-100 justify-content-around">
            <div class="col-xs-6">
              <div class="table">
                <table class="table table-striped">
                  <tr>
                    <td>
                      <TextInput
                        source="name"
                        label="Imię i nazwisko"
                        variant="outlined"
                        fullWidth
                        validate={requiredField}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                        source="academicDegree"
                        label="Stopień naukowy"
                        variant="outlined"
                        fullWidth
                        validate={requiredField}
                      />
                    </td>
                  </tr>
                  {/*<tr>*/}
                  {/*  <td>*/}
                  {/*    <SelectInput*/}
                  {/*      source="type"*/}
                  {/*      label="Typ"*/}
                  {/*      variant="outlined"*/}
                  {/*      choices={[*/}
                  {/*        { id: 0, name: "Typ 1" },*/}
                  {/*        { id: 1, name: "Typ 2" },*/}
                  {/*        { id: 2, name: "Typ 3" },*/}
                  {/*      ]}*/}
                  {/*      fullWidth*/}
                  {/*      validate={requiredField}*/}
                  {/*    />*/}
                  {/*  </td>*/}
                  {/*</tr>*/}
                  <tr>
                    <td>
                      <TextInput
                        source="specialization"
                        label="Specjalizacja"
                        variant="outlined"
                        fullWidth
                        validate={requiredField}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <AddImagePopup source="photo" reference="media_objects" />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Opis pełny
                      </label>
                      <Field name="description">
                        {(props) => (
                          <TinyMCEInput
                            source="description"
                            height={200}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <NumberInput
                        source="portalId"
                        label="ID w portalu pacjenta"
                        variant="outlined"
                        fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TextInput
                          source="formUrl"
                          label="Link do formularza"
                          variant="outlined"
                          fullWidth
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ReferenceArrayInput
                        source="clinic"
                        reference="clinics"
                        label="Poradni"
                        perPage={1000}
                        fullWidth
                      >
                        <AutocompleteArrayInput variant="outlined" fullWidth />
                      </ReferenceArrayInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ReferenceArrayInput
                        source="hospitalSection"
                        reference="hospital_sections"
                        label="Oddziały szpitala"
                        perPage={1000}
                        fullWidth
                      >
                        <AutocompleteArrayInput variant="outlined" fullWidth />
                      </ReferenceArrayInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ReferenceArrayInput
                          source="diagnosis"
                          reference="diagnoses"
                          label="Badania diagnostyczne"
                          perPage={1000}
                          fullWidth
                      >
                        <AutocompleteArrayInput variant="outlined" fullWidth />
                      </ReferenceArrayInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <ReferenceArrayInput
                          source="staff"
                          reference="staff"
                          label="Lekarze"
                          perPage={1000}
                          fullWidth
                      >
                        <AutocompleteArrayInput variant="outlined" fullWidth />
                      </ReferenceArrayInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                          source="public"
                          label="Publiczne"
                          variant="outlined"
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          </FormTab>
          <FormTab label={'Centrum Zdrowego Kręgosłupa'}>
            <div className="d-flex w-100 justify-content-around">
              <div class="col-xs-6">
                <div class="table">
                  <table class="table table-striped">
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Treść krótka Centrum Zdrowego Kręgosłupa
                      </label>
                      <Field name="shortCentrum">
                        {(props) => (
                          <TinyMCEInput
                            source="shortCentrum"
                            height={200}
                            menubar={false}
                          />
                        )}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label
                        style={{
                          fontFamily: "arial",
                          color: "rgba(0, 0, 0, 0.54)",
                          display: "block",
                        }}
                      >
                        Opis długi Centrum Zdrowego Kręgosłupa
                      </label>
                      <Field name="descriptionCentrum">
                        {(props) => <TinyMCEInput source="descriptionCentrum" />}
                      </Field>
                    </td>
                  </tr>
                  <tr>
                   <td>
                      <NumberInput
                        source="positionCentrum"
                        label="Pozycja w Centrum Zdrowego Kręgosłupa"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <BooleanInput
                        source="showCentrum"
                        label="Pokaż w Centrum Zdrowego Kręgosłupa"
                        variant="outlined"
                      />
                    </td>
                  </tr>
                  </table>
                </div>
              </div>
            </div>
          </FormTab>
        </TabbedForm>
      </Create>
    </>
  );
};
