import * as React from "react";
import {Filter, FunctionField, List, TextField, TextInput,BooleanField} from "react-admin";

import CustomDataGrid from "../CustomElements/CustomDataGrid";
import {CustomActionsButtons} from "../CustomElements/CustomActionButtonsField";
import CustomActionBar from "../CustomElements/CustomActionBar";
import { CustomPagination } from "../CustomElements/CustomPagination";

import EmptyList from "../EmptyList";

const GetPositionName = (position) => {
  switch (position) {
    case 0:
      return "Duży baner";
    case 1:
      return "Mały baner";
    case 2:
      return "Mały baner z filmikiem";
    default:
      return "Błąd pozycji";
  }
};

export default (props) => {

   const bannerType = (type) => {
       switch (type) {
           case 0:
               return "Baner na stronie głównej";
           case 1:
               return "Baner w Centrum Zdrowego Kręgosłupa";
           default:
               return "-";
       }
   };


  return (
    <List
      {...props}
      title={`Banery`}
      perPage={25}
      pagination={<CustomPagination />}
      bulkActionButtons={false}
      actions={<CustomActionBar />}
      empty={<EmptyList title="banerów" add={true} />}
      filters={
        <Filter>
          <TextInput
            label="Nazwa"
            source="name"
            variant="outlined"
            alwaysOn
            resettable
          />
        </Filter>
      }
    >
      <CustomDataGrid>

        <TextField source="name" label="Nazwa" />
        <BooleanField
            source="showOnMainSite"
            label="Opublikowany"
            // classes={classes}
        />
        <FunctionField
            source="type"
            render={(record) => bannerType(record.type)}
            label="Typ baneru"
        />
        <CustomActionsButtons label="Akcje" notUndoable={true}/>
      </CustomDataGrid>
    </List>
  );
};
