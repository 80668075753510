import React from "react";
import {AutocompleteArrayInput, Create, ReferenceArrayInput, required, SimpleForm, TextInput,BooleanInput} from "react-admin";
import {Field} from "react-final-form";

import TinyMCEInput from "../TinyMCEInput";

import {PostBottomToolbarAdd} from "../CustomElements/PostBottomToolbars";
import AddImagePopup from "../CustomElements/AddImagePopup";

const requiredField = required();

export default (props) => {
  return (
    <Create {...props} title={`Dodaj dokument`}>
      <SimpleForm toolbar={<PostBottomToolbarAdd />}>
        <div className="d-flex w-100 justify-content-around">
          <div class="col-xs-6">
            <div class="table">
              <table class="table table-striped">
                <tr>
                  <td>
                    <TextInput
                      source="name"
                      label="Nazwa"
                      variant="outlined"
                      fullWidth
                      validate={requiredField}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <AddImagePopup source="image" reference="media_objects" />
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Treść krótka
                    </label>
                    <Field name="short">
                      {(props) => (
                        <TinyMCEInput
                          source="short"
                          height={200}
                          menubar={false}
                          id="1"
                        />
                      )}
                    </Field>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label
                      style={{
                        fontFamily: "arial",
                        color: "rgba(0, 0, 0, 0.54)",
                        display: "block",
                      }}
                    >
                      Opis pełny
                    </label>
                    <Field name="description">
                      {(props) => <TinyMCEInput source="description" id="2" />}
                    </Field>
                  </td>
                </tr>

                <tr>
                  <td>
                    <ReferenceArrayInput
                      source="hospitalSection"
                        reference="hospitalSections"
                        label="Oddziały szpitala"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="clinic"
                        reference="clinics"
                        label="Poradnie"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="diagnosis"
                        reference="diagnoses"
                        label="Badania diagnostyczne"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <ReferenceArrayInput
                        source="relatedPosts"
                        reference="blogs"
                        label="Powiązane artykuły"
                        perPage={1000}
                        fullWidth
                    >
                      <AutocompleteArrayInput variant="outlined" fullWidth />
                    </ReferenceArrayInput>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                        source="recommended"
                        label="Polecany"
                        variant="outlined"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                        source="recommendedCentrum"
                        label="Polecany Centrum Zdrowego Kręgosłupa"
                        variant="outlined"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <BooleanInput
                        source="public"
                        label="Publiczne"
                        variant="outlined"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};
